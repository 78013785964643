import React, { Component } from 'react';
import { Loader, Dimmer, Icon, Button } from 'semantic-ui-react';
import Axios from 'axios';

const tags = ['all', 'customersupport', 'dataaccuracy', 'easytouse', 'fast', 'costdata', 'mc', 'savesmoney', 'savestime', 'generic'];
const tagconverter = {
    all: 'Everything',
    customersupport: 'Customer Support',
    dataaccuracy: 'Data Quality/Accuracy',
    easytouse: 'Ease Of use',
    fast: 'Speed',
    costdata: 'Cost Data/Site Extraction',
    mc: 'Market Analysis',
    savesmoney: 'Saving Money',
    savestime: 'Saving Time',
    generic: 'How Awesome Spark Is'
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allquotes: [],
            filteredquotes: [],
            tag: 'all',
            width: window.innerWidth
        };
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateWidth);  
        this.pullData();
    }

    updateWidth = () => {
        this.setState({width: window.innerWidth});
    }

    pullData = () => {
        const formdata = new FormData();
        formdata.set('checktype', 'pullquotes');
        Axios({
            method: 'post',
            url: 'https://reviews.sparkforappraisers.com/internalapi.php',
            data: formdata
        }).then(response => {
            let quotes = response.data;
            this.shuffle(quotes);
            quotes.sort((a, b) => {
                return +b.favorite - +a.favorite;
            })
            this.setState({allquotes: quotes, filteredquotes: quotes, loading: false});
        });
    }

    shuffle(array) {
        let currentIndex = array.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    handleSelectChange = tag => {
        this.setState({tag}, () => {
            let filteredquotes;
            if (tag === 'all') {
                filteredquotes = this.state.allquotes.slice();
            }else {
                filteredquotes = this.state.allquotes.filter(item => {
                    return +item[tag] === 1;
                });
            }
            this.setState({filteredquotes});
        });
    }

    redactQuote = quote => {
        return quote.replace(/data master/gi, '[Redacted Competitor]').replace(/datamaster/gi, '[Redacted Competitor]').replace(/\[COMPETITOR\]/gi, '[Redacted Competitor]').replace(/\[spark competitor\]/gi, '[Redacted Competitor]').replace(/\[your competitor\]/gi, '[Redacted Competitor]').replace(/\[the competitor\]/gi, '[Redacted Competitor]').replace(/DM/g, '[Redacted Competitor]');
    }

    render() {
        return(
            <div style={{background: '#ebebeb'}}>
                {this.state.loading &&
                    <Dimmer active>
                        <Loader>Loading Reviews...</Loader>
                    </Dimmer>
                }

                {/* original is background: '#025887' */}
                <div style={{width: '100%',background: '#1a263c', position: 'relative', padding: '10px', textAlign: 'center'}}>
                    <p style={{margin: 0, padding: 0, fontWeight: 'bold', color: 'white', fontSize: this.state.width/22 > 42 ? 42 : this.state.width/22, lineHeight: .9}}>What Are Appraisers Saying About Spark??</p><p style={{margin: '20px auto', fontStyle: 'italic', padding: 0, color: 'white', fontSize: this.state.width/40 > 30 ? 30 : this.state.width/40, lineHeight: .8}}>{this.state.allquotes.length === 0 ? 'Check Out These Spark Reviews' : 'Check Out ' + this.state.allquotes.length + ' Quotes From Your Peers!'}</p>
                    {/*<p style={{margin: '10px 0 0 0', color: '#bcbcbc', fontWeight: 'bold', lineHeight: 1, fontSize: this.state.width/35 < 10 ? 10 : this.state.width/35 > 30 ? 30 : this.state.width/35}}>See Below What Other Appraisers Like You Are Saying About</p>
                    <p style={{margin: '0 0 0 0', color: '#bcbcbc', fontWeight: 'bold', lineHeight: 1, fontSize: this.state.width/35 < 10 ? 10 : this.state.width/35 > 30 ? 30 : this.state.width/35}}>How Spark Has Helped Transform Their Business</p>*/}
                    <p style={{margin: '12px 0 0 0', color: 'white', fontSize: this.state.width/44 < 10 ? 10 : this.state.width/44 > 20 ? 20 : this.state.width/44}}>Show Me Quotes About:</p>
                    <select style={{}} value={this.state.tag} className="dropdown1-el" onChange={e => this.handleSelectChange(e.target.value)}>
                        {tags.map(tag => {
                            return <option key={tag} value={tag}>{tagconverter[tag]}</option>
                        })}
                    </select>
                    <div style={{height: 36, width: 150, margin: '20px auto 0 auto'}}>
                        <Button fluid className="buttondiv" color="sky blue" onClick={() => window.location = 'https://www.sparkforappraisers.com?utm_source=reviewpage'}>Learn More</Button>
                    </div>
                    {/* <p style={{margin: '4px 0 0 0', fontSize: this.state.width/44 < 10 ? 10 : this.state.width/44, color: '#e69500'}}>We'll set it up for you for <em>FREE!</em></p> */}
                </div>

                <div style={{margin: '20px auto', width: '90%', maxWidth: 1000, textAlign: 'center'}}>
                    {this.state.filteredquotes.map((item, index) => {
                        return <React.Fragment key={index}>
                                <div style={{background: (index+1) % 2 === 0 ? '#eefbfb' : 'white', width: '100%', marginTop: 10, borderRadius: 3, padding: '10px 10px 0 10px', minHeight: 50}}>
                                <div style={{width: '100%'}}>
                                    <Icon name="quote left" style={{color: '#888'}}></Icon>
                                    <span style={{padding: 0, marginLeft: 4, marginRight: 4}}>{this.redactQuote(item.quote)}</span>
                                    <Icon name="quote right" style={{color: '#888'}}></Icon>
                                </div>
                                <div style={{width: '100%', textAlign: 'right', marginTop: 8}}>
                                    <span style={{fontStyle: 'italic'}}>- {item.name}</span>
                                </div>
                            </div>
                            {(index+1) % 8 === 0 &&
                                <div className="buttondiv" style={{marginTop: 21, marginBottom: 16}}>
                                    <Button className="buttondiv" color="sky blue" onClick={() => window.location = 'https://www.sparkforappraisers.com?utm_source=reviewpage'}>Learn More And Start Your Free Trial</Button>
                                    <p style={{color: 'dark grey', fontSize: 16}}>WE'LL SET IT UP FOR YOU FOR <em>FREE!</em></p>
                                </div>

                            }
                        </React.Fragment>
                    })}
                </div>
                <div style={{width: '100%', height: 30}}></div>
            </div>
            
        );
    }
}

export default App;